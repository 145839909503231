@import "C:/OSPanel/node_modules/bootstrap-4-grid/scss/grid.scss";

article, aside, details, figcaption, figure, footer, header, main, menu, nav, section, summary {
	display: block;
	position: relative;
	z-index: 1;
}

div {
	// outline: 1px solid #3a87ad !important;
}
form {
	// outline: 1px solid blue !important;
}
table {
	// outline: 1px solid green !important;
}

body{
	overflow: hidden;
	overflow-y: scroll;
	background: #f8f8f8;
}

.group:before,
.group:after {
	content: "";
	display: table;
} 
.group:after {
	clear: both;
}
.group {
	zoom: 1; /* For IE 6/7 (trigger hasLayout) */
}

.wrapper{
	position: relative;
	&:after{
		content: '';
		position: absolute;
		left: 0;
		right: 0;
		top: 0;
		height: 100%;
		width: 100%;
		margin: 0 auto;
		background: linear-gradient(to bottom, #f2f2f2 0%,#fefefe 35%,#f2f2f2 70%,#fdfdfd 100%);
		border: 1px solid #eaeaea;
		// @include make-container();
		@include make-container-max-widths();
		z-index: 0;
	} 
}

header{
	padding: 10px 0 30px;
	background: url(../images/header.png) 0 0;
	@media (max-width: 450px) {
		overflow: hidden;
	}
	> .container{
		> .row{
			@media (max-width: 450px) {
				margin-right: 0;
				margin-left: 0;
				> [class^="col-"],
				> [class*=" col-"] {
					padding-right: 0;
					padding-left: 0;
				}
			}
			> div{
				&:nth-child(1){
					@media (max-width: 450px) {
						flex: 0 0 20%;
						max-width: 20%;
					}
				}
				&:nth-child(3){
					@media (max-width: 450px) {
						flex: 0 0 80%;
						max-width: 80%;
					}
				}
			}
		}
	}
	.top_phone{
		top: 0;
		right: unset;
		position: relative;
		text-align: left;
		line-height: 27px;
		padding: 0 0 0 30px;
		@include media-breakpoint-down(lg){
			text-align: right;
		}
		@include media-breakpoint-down(sm){
			text-align: left;
		}
		@include media-breakpoint-down(xs){
			text-align: right;
			padding: 0;
		}
		span{
			font-weight: bold;
			position: relative;
			@include media-breakpoint-down(xs){
				font-size: 16px;
			}
			&:before{
				content: '';
				position: absolute;
				left: -30px;
				top: 0;
				width: 25px;
				height: 25px;
				background: url(../images/phone.svg) 0 0 no-repeat;
				background-size: contain;
				@include media-breakpoint-down(xs){
					width: 20px;
					height: 20px;
				}
			}
			a{
				color: #333b40;
				text-decoration: none;
			}
		}
	}
	.zvonok{
		position: relative;
		left: 0;
		top: 0;
		margin: 10px auto;
		text-align: center;
		max-width: 200px;
		width: 100%;
		box-shadow: inset 3px 3px 2px 0 rgba(0, 0, 0, 0.15);
		@include media-breakpoint-up(sm){
			margin: 10px 0 0;
		}
		@include media-breakpoint-down(lg){
			margin: 0 0 0 auto;
		}
		@include media-breakpoint-down(sm){
			margin: 0 auto 0 0;
		}
		@media (max-width: 450px) {
			padding: 10px 0;
		}
		&:hover{
			background: #7e929d;
		}
	}
	.top_email{
		color: #333b40;
		font-size: 20px;
		font-weight: bold;
		text-align: right;
		white-space: nowrap;
		line-height: 27px;
		@include media-breakpoint-down(xs){
			font-size: 16px;
		}
		@include media-breakpoint-down(sm){
			padding: 0 0 0 38px;
		}
		@include media-breakpoint-down(lg){
			font-size: 16px;
		}
		span{
			position: relative;
			&:before{
				content: '';
				position: absolute;
				left: -35px;
				top: 0;
				width: 25px;
				height: 25px;
				background: url(../images/email.svg) 0 0 no-repeat;
				background-size: contain;
				@include media-breakpoint-down(xs){
					width: 20px;
					height: 20px;
				}
				@include media-breakpoint-down(lg){
					top: -3px;
				}
			}
		}
	}
	.connect{
		margin: 10px 0 0;
		text-align: right;
		background: rgba(241, 241, 241, 0.2);
		display: inline-block;
		padding: 10px 0 5px;
		border-radius: 5px;
		float: right;
		max-width: 200px;
		@include media-breakpoint-up(md){
			margin: 30px 0 0;
		}
		@include media-breakpoint-down(xs){
			margin: 0;
		}
		@extend .group;
		a{
			display: inline-block;
			width: 22px;
			overflow: hidden;
			z-index: 0;
			transition: width 1s ease;
			margin: 0 8px;
			&.telegram{
				width: 24px;
			}
			&.whatsapp{
			}
			&:hover{
				z-index: 1;
				transition: width 0.5s ease;
				&.viber{
					width: 55px;
				// @include mobile {
				// 	width: 22px;
				// }
			}
			&.telegram{
				width: 78px;
				// @include mobile {
				// 	width: 24px;
				// }
			}
			&.whatsapp{
				width: 80px;
				// @include mobile {
				// 	width: 22px;
				// }
			}
		}
	}
}
.time{
	position: relative;
	font-weight: bold;
	color: #333b40;
	line-height: 27px;
	padding: 0 0 0 30px;
	@include media-breakpoint-down(sm){
		text-align: right;
	}
	@include media-breakpoint-down(xs){
		font-size: 16px;
		padding: 0;
	}
	.title{
		font-weight: normal;
		@include media-breakpoint-down(md){
		}
		&:before{
			content: '';
			position: absolute;
			left: 0;
			top: 0;
			width: 25px;
			height: 25px;
			background: url(../images/clock.svg) 0 0 no-repeat;
			background-size: contain;
			@include media-breakpoint-down(sm){
				left: inherit;
				right: 120px;
			}
		}
	}
	span{
		font-size: 16px;
		@include media-breakpoint-down(xs){
			position: relative;
			padding: 0 0 0 30px;
			white-space: nowrap;
		}
		&:before{
			display: none;
			content: '';
			position: absolute;
			left: 0;
			top: 0;
			width: 25px;
			height: 25px;
			background: url(../images/clock.svg) 0 0 no-repeat;
			background-size: contain;
			@include media-breakpoint-down(xs){
				display: block;
				top: -3px;
				width: 20px;
				height: 20px;
			}
		}
	}
}
.logo{
	background: url(../images/logo.png) 0 0 no-repeat;
	@include media-breakpoint-down(lg){
		background-size: contain;
	}
}
a{
	&.logo{
		position: relative;
		text-indent: unset;
		top: 0;
		left: 0;
		width: 100%;
		min-width: 256px;
		@include media-breakpoint-down(lg){
			min-width: 200px;
		}
		@include media-breakpoint-down(md){
			height: 110px;
		}
		@media (max-width: 450px) {
			height: 70px;
		}
		@media (max-width: 395px) {
			height: 60px;
		}
	}
	&.cart1{
		color: #fff;
		font-size: 16px;
		font-weight: bold;
		padding: 10px;
		margin: 10px 0 0;
		position: relative;
		top: 0;
		text-indent: unset;
		height: auto;
		right: inherit;
		background: #7e929d;
		border: 2px solid #fff;
		border-radius: 5px;
		text-align: center;
		max-width: 200px;
		width: 100%;
		box-shadow: inset 3px 3px 2px 0 rgba(0, 0, 0, 0.15);
		@include media-breakpoint-down(lg){
			margin: 2px 0 0;
		}
		@include media-breakpoint-down(md){
			margin: 2px 0 0 auto;
		}
		@include media-breakpoint-down(xs){
			margin: 5px auto;
			font-size: 14px;
			padding: 6px 20px;
		}
		&:before{
			content: '';
			position: absolute;
			left: 10px;
			top: 0;
			bottom: 0;
			margin: auto 0;
			width: 30px;
			height: 30px;
			background: url(../images/cart.svg) 0 0 no-repeat;
			background-size: contain;
			@include media-breakpoint-down(xs){
				width: 20px;
				height: 20px;
			}
		}
		&:hover{
			background: #4c89ab;
			text-decoration: none;
		}
	}
}
}
nav{
	.top_menu{
		display: flex;
		justify-content: space-around;
		position: relative;
		top: 0;
		background: linear-gradient(to bottom, #f2f2f2 0%,#fefefe 35%,#f2f2f2 70%,#fdfdfd 100%);
		box-shadow: 0 2px 2px rgba(0,0,0,0.2);
		border-radius: 3px;
		border: 1px solid #fff;
		margin: -30px 0 0;
		@include media-breakpoint-down(md){
			padding: 7px 10px 5px 0;
		}
		> div{
			padding: 19px 10px;
			@include media-breakpoint-down(md){
				display: none;
			}
			&:last-child,
			&:nth-last-child(2),
			&:nth-last-child(3){
				display: none;
			}
		}
		&.menu_state_open{
			flex-direction: column;
			> div{
				display: block;
				padding: 10px;
				&:not(:last-child){
					border-bottom: 1px solid #c7cbce;
				}
				&:last-child{
					background: url(../images/cart-black.svg) 10px 1px no-repeat;
					background-size: contain;
				}
			}
			.menu__icon{
				span{
					&:nth-child(1) {
						top: 18px;
						width: 0%;
						left: 50%;
					}
					&:nth-child(2) {
						transform: rotate(45deg);
					}
					&:nth-child(3) {
						transform: rotate(-45deg);
					}
					&:nth-child(4) {
						top: 18px;
						width: 0%;
						left: 50%;
					}
				}
			}
		}
	}
	.top_menu_title{
		font-size: 17px;
		font-weight: bold;
		position: absolute;
		top: 5px;
		left: 0;
		right: 0;
		margin: 0 auto;
		width: fit-content;
		z-index: 1;
		padding: 12px 0 0;
		cursor: pointer;
		@include media-breakpoint-up(lg){
			display: none;
		}
	}
	.menu__icon {
		position: relative;
		z-index: 2;
		display: none;
		width: 45px;
		height: 35px;
		cursor: pointer;
		margin: 0 0 0 auto;
		border: none !important;
		@include media-breakpoint-down(md){
			display: block !important;
		}
		span {
			display: block;
			position: absolute;
			height: 9px;
			width: 100%;
			margin: auto 0;
			background: #57656d;
			border-radius: 9px;
			opacity: 1;
			left: 0;
			transform: rotate(0deg);
			transition: .25s ease-in-out;
			&:nth-child(1) {
				top: 0px;
			}
			&:nth-child(2),
			&:nth-child(3) {
				top: 13px;
			}
			&:nth-child(4) {
				top: 26px;
			}
		}
	}
}
.stroka{
	position: relative;
	left: 0;
	top: 0;
	width: 100%;
	padding: 10px 0;
	@include media-breakpoint-down(md){
		padding: 10px 0 0;
	}
	> .container{
		> div{
			@include media-breakpoint-down(lg){
				width: 100% !important;
			}
		}
	}
}
.top_menu{
	display: flex;
}
.calc-l-btn{
	width: 100%;
	margin: 0;
	background: linear-gradient(to bottom, #303030, #4e5051);
	border-radius: 5px;
	border: 1px solid #3d3f40;
	text-align: center;
	@include media-breakpoint-down(md){
		order: 1;
	}
	&:hover{
		background: linear-gradient(to bottom, #282828, #282828);
	}
	span{
		display: inline-block;
		position: relative;
		line-height: 41px;
		padding: 0;
		text-align: center;
		&:before{
			content: '';
			position: absolute;
			left: -35px;
			top: 0;
			bottom: 0;
			margin: auto 0;
			width: 25px;
			height: 25px;
			background: url(../images/calculator.svg) 0 0 no-repeat;
			background-size: contain;
		}
	}
}
.left-menu{
	@include media-breakpoint-down(md){
		display: flex;
		flex-direction: column;
	}
	.lci{

		padding: 0;
		margin: 20px 0 0;
		.title{
			position: relative;
			margin: 0 0 10px;
			>span{
				position: relative;
				z-index: 1;
				display: block;
				font-size: 16px;
				font-weight: bold;
				padding: 10px;
				margin: 0 auto;
				color: #fff;
				background: #7e929d;
				border-radius: 5px;
				text-align: center;
				box-shadow: inset 3px 3px 2px 0 rgba(0, 0, 0, 0.15);
				@include media-breakpoint-down(md){
					cursor: pointer;
				}
			}
			&.menu_state_open{
				.menu__icon{
					span{
						&:nth-child(1) {
							top: 18px;
							width: 0%;
							left: 50%;
						}
						&:nth-child(2) {
							transform: rotate(45deg);
						}
						&:nth-child(3) {
							transform: rotate(-45deg);
						}
						&:nth-child(4) {
							top: 18px;
							width: 0%;
							left: 50%;
						}
					}
				}
			}
			.menu__icon {
				position: absolute;
				top: 6px;
				right: 15px;
				z-index: 2;
				display: none;
				width: 35px;
				height: 27px;
				cursor: pointer;
				margin: 0 0 0 auto;
				border: none !important;
				@include media-breakpoint-down(md){
					display: block !important;
				}
				span {
					display: block;
					position: absolute;
					height: 7px;
					width: 100%;
					margin: auto 0;
					background: #fff;
					border-radius: 9px;
					opacity: 1;
					left: 0;
					transform: rotate(0deg);
					transition: .25s ease-in-out;
					&:nth-child(1) {
						top: 0px;
					}
					&:nth-child(2),
					&:nth-child(3) {
						top: 10px;
					}
					&:nth-child(4) {
						top: 20px;
					}
				}
			}
		}
		.lm_block{
			margin: 0;
			@include media-breakpoint-down(md){
				display: none;
			}
			&.menu_state_open{
				transition: transform 0.2s ease-in-out;
				@include media-breakpoint-down(md){
					display: block;
				}
				&.expanded{
					transition: transform 0.2s ease-in-out;
					.sub_btn_menud{
						&.__1{
							&.expanded{
								display: block;
								width: 100%;
								left: 120%;
							}
						}
					}
				}
			}
		}
	}
}
.sm_btn_1,
.nsm_btn_1,
.sm_btn_open{
	display: block;
	position: relative;
	width: 100%;
	height: auto;
	border-radius: 5px;
	@include media-breakpoint-down(md){
		position: static;
	}
	a{
		display: block;
		padding: 15px 0;
	}
}
.sub-menu{
	> a{
		position: relative;
		&:before {
			content: '\003E';
			font-size: 22px;
			font-weight: bold;
			position: absolute;
			top: 15px;
			right: 10px;
			@include media-breakpoint-up(lg){
				display: none;
			}
		}
	}
}
.sm_btn_open{
	&.nsm_btn_1{
		&:hover{
			background: #dadfe2;
			border-bottom: 1px solid #c4ccd1;
			> a{
				color: #666;
			}
		}
	}
	&.sm_btn_1{
		&:hover{
			background: #dadfe2;
			border-bottom: 1px solid #c4ccd1;
			> a{
				color: #666;
			}
		}
	}
}
.sub_btn_menud{
	background: #f1f1f1;
	border-radius: 5px !important;
	&.__1{
		position: absolute;
		top: 0;
		left: 100%;
		min-width: 100%;
		width: auto;
		box-shadow: 3px 3px 2px 0 rgba(0, 0, 0, 0.15);
		border: 1px solid #7e929d;
		z-index: 3;
		display: none;
		.sub_btn_menu{
			@include media-breakpoint-down(md){
				padding: 10px 20px;
			}
			.menu_back{
				position: relative;
				font-size: 22px;
				text-align: left;
				flex: 1 1 100%;
				padding: 0 0 0 20px;
				@include media-breakpoint-down(md){
					border-bottom: 1px solid #dedede;
					padding: 0 0 10px 20px;
				}
				@include media-breakpoint-up(lg){
					display: none;
				}
				&:before {
					content: '';
					font-size: 22px;
					font-weight: bold;
					position: absolute;
					top: 8px;
					left: 0;
					width: 30px;
					height: 30px;
					background: url(../images/arrow-right.svg) 0 0 no-repeat;
				}
			}
			.sub_btn_menu2{
				display: flex;
				padding: 0;
				@include media-breakpoint-down(md){
					flex-wrap: wrap;
				}
				.link_title{
					font-size: 22px;
					color: #346783;
					flex: 1 1 100%;
					text-align: left;
					padding: 10px 0;
					border-bottom: 1px solid #dedede;
					@include media-breakpoint-up(lg){
						display: none;
					}
				}
				._groupedx{
					flex: 1 1 calc(50% - 20px);
					background: none;
					white-space: nowrap;
					padding: 10px;
					@include media-breakpoint-down(md){
						padding: 0;
					}
					&:not(:last-child){
						border-right: 1px solid #dedede;
						@include media-breakpoint-down(md){
							border-right: none;
							border-bottom: 1px solid #dedede;
						}
					}
					&_title{
						font-size: 22px;
						font-weight: bold;
						color: #346783;
						text-align: left;
						padding: 0 0 5px;
						@include media-breakpoint-down(md){
							padding: 10px 0;
							border-bottom: 1px solid #dedede;
						}
						&:hover{
							text-decoration: none;
						}
					}
					&_body{
						text-align: left;
						> div{
							a{
								padding: 5px 0;
								@include media-breakpoint-down(md){
									padding: 10px 0;
									border-bottom: 1px solid #dedede;
								}
								&:hover{
									color: #4c89ab;
								}
								&.active{
									
								}
							}
							.nsm_btn_2{
								background: url(../images/s2.png) left 12px no-repeat;
								a{
									padding: 5px 0;
								}
							}
						}
					}
				}
			}
		}
	}
}
.sm_btn_1,
.nsm_btn_1{
	&:hover{
		background: #4c89ab;
		> a{
			color: #fff;
		}
		.sub_btn_menud{
			@include media-breakpoint-up(lg) {
				display: block;
			}
		}
	}
}
.sub_btn_menud {
	.nsm_btn_2{
		background: url(../images/s2.png) left 12px no-repeat;
		a{
			padding: 5px 0;
		}
	}
}
.sub_btn_menud{
	border-radius: 0 0 5px 5px;
}
.gbook,
.news{
	margin: 20px 0 0;
	&.menu_state_open{
		.ttl{
			.menu__icon{
				span{
					&:nth-child(1) {
						top: 18px;
						width: 0%;
						left: 50%;
					}
					&:nth-child(2) {
						transform: rotate(45deg);
					}
					&:nth-child(3) {
						transform: rotate(-45deg);
					}
					&:nth-child(4) {
						top: 18px;
						width: 0%;
						left: 50%;
					}
				}
			}
		}
		.cnt{
			@include media-breakpoint-down(md){
				display: block;
			}
		}
	}
	.ttl{
		position: relative;
		padding: 0;
		text-transform: none;
		display: block;
		font-size: 16px;
		font-weight: bold;
		padding: 10px;
		margin: 0 auto;
		color: #fff;
		background: #7e929d;
		border-radius: 5px;
		text-align: center;
		box-shadow: inset 3px 3px 2px 0 rgba(0, 0, 0, 0.15);
		.menu__icon {
			position: absolute;
			top: 6px;
			right: 15px;
			z-index: 2;
			display: none;
			width: 35px;
			height: 27px;
			cursor: pointer;
			margin: 0 0 0 auto;
			border: none !important;
			@include media-breakpoint-down(md){
				display: block !important;
			}
			span {
				display: block;
				position: absolute;
				height: 7px;
				width: 100%;
				margin: auto 0;
				background: #fff;
				border-radius: 9px;
				opacity: 1;
				left: 0;
				transform: rotate(0deg);
				transition: .25s ease-in-out;
				&:nth-child(1) {
					top: 0px;
				}
				&:nth-child(2),
				&:nth-child(3) {
					top: 10px;
				}
				&:nth-child(4) {
					top: 20px;
				}
			}
		}
		&:hover{
			text-decoration: none;
			background: #4c89ab;
		}
		a{
			color: #fff;
			height: auto;
			text-align: center;
			background: none;
			text-indent: unset;
		}
	}
	.cnt{
		padding: 10px;
		@include media-breakpoint-down(md){
			display: none;
		}
	}
}
.news{
	.ttl{
		&:hover{
			background: #7e929d;
		}
	}
}
.path_p2{
	padding: 0;
}
.outer_content{
	padding: 20px 0 15px;
	overflow: hidden;
	table{
		@include media-breakpoint-down(md){
			overflow-x: scroll;
			width: 100% !important;
			display: block;
		}
		tbody{
			@include media-breakpoint-down(lg){
			}
		}
	}
	img {
		@include media-breakpoint-down(xl){
			max-width: 100%;
			height: auto;
		}
	}
}
.art_outer_ttl{
	height: auto;
	text-align: right;
}
.tbl_arts{
	.art_title{
		.art_outer_ttl{
			display: flex;
			justify-content: space-between;
			.art_ttl{
				text-align: left;
			}
			.art_sends{
				min-width: 190px;
			}
		}
	}
}
#frmpost{
	.qa_cell{
		text-align: left;
		margin: 0 0 10px;
		@extend .group;
	}
	.form_line{

	}
}
footer{
	padding: 0 0 10px;
	.footer{
		background: red;
		border-radius: 5px;
		padding: 10px;
		background: #7e929d;
		box-shadow: inset 3px 3px 2px 0 rgba(0, 0, 0, 0.15);
	}
	.abouts{
		position: relative;
		left: 0;
		margin: 0;
		width: auto;
		.adr,
		.tel,
		.email{
			display: flex;
			left: 0;
			position: relative;
			top: inherit;
			line-height: 22px;
			@include media-breakpoint-down(xs){
				justify-content: center;
			}
			span{
				position: relative;
				margin: 0;
				padding: 0 10px 0 0;
			}
			b{
				font-weight: normal;
			}
		}
		.email{
			padding: 10px 0;
		}
	}
	.counter{
		position: relative;
		left: 0;
		margin: 0;
		@include media-breakpoint-down(xs){
			text-align: center;
		}
	}
	.sitemap{
		position: relative;
		right: inherit;
		top: inherit;
		padding: 10px 0;
		@include media-breakpoint-down(xs){
			text-align: center;
		}
	}
	.oplata-ttl{
		position: relative;
		left: 0;
		top: 0;
		padding: 0 0 10px;
		width: auto;
		background: none;
		@include media-breakpoint-down(xs){
			text-align: center;
		}
		+ img{
			@include media-breakpoint-down(xs){
				display: block;
				margin: 0 auto;
			}
		}
	}
	.footer-menu{
		@include media-breakpoint-down(sm){
			border-bottom: 1px solid #4f5d65;
			padding: 0 0 10px;
		}
		.title{
			font-size: 20px;
			color: #303538;
			padding: 0 0 10px;
			@include media-breakpoint-down(xs){
				text-align: center;
			}
		}
		&.left{
			.vnt_2{
				text-align: left;
				@include media-breakpoint-down(xs){
					text-align: center;
				}
				a{
					display: block;
					color: #fff;
					font-size: 15px;
					padding: 3px 0;
				}
			}
			.selectr{
				a{
					color: #303538;
				}
			}
			> div{
				&:last-child,
				&:nth-last-child(2),
				&:nth-last-child(3){
					display: none;
				}
			}
		}
		&.right{
			.lm_block{
				border: none;
				margin: 0;
				border-radius: 0;
				.sub-menu{
					> a{
						&:before{
							display: none;
						}
					}
				}
				.sm_btn_1,
				.nsm_btn_1,
				.sm_btn_open{
					border: none;
					text-align: left;
					@include media-breakpoint-down(xs){
						text-align: center;
					}
					&:hover{
						background: none;
						.sub_btn_menud{
							display: none;
						}
						> a{
							text-decoration: underline;
						}
					}
					> a{
						padding: 3px 0;
						color: #fff;
					}
				}
				.sm_btn_1{
					background: none;
					> a{
						text-decoration: underline;
						color: #303538;
					}
				}
			}
		}
	}
}
.fl1-form,
.fl2-form,
.fl3-form{
	@include media-breakpoint-down(xs){
		height: 100%;
		max-height: 440px;
		width: 90%;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		margin: auto;
	}
}
.fl1-inp2,
.fl1-inp3,
.fl1-inp4,
.fl1-inp5,
.fl1-inp6{
	.fl1-lbl,
	.fl1-inp{
		@include media-breakpoint-down(xs){
			display: block;
			width: 100%;
			padding: 10px;
		}
	}
}
.prd_outer_short{
	padding: 10px 0;
	@include media-breakpoint-down(sm){
		margin: 0 0 10px;
		border-top: 1px solid #eaeaea;
	}
	.prd_ttl_left,
	.ppd_list_item,
	.btn_add{
		@include media-breakpoint-down(sm){
			text-align: center;
		}
	}
}
.pprd_opts{
	width: auto;
}
.cart_ot_f{
	input{
		@include media-breakpoint-down(sm){
			font-size: 17px;
			padding: 5px 10px;
			width: 100%;
		}
	}
}
.cart_t{
	border: none;
	@include media-breakpoint-down(sm){
		display: block;
		border: none;
	}
	tbody{
		@include media-breakpoint-down(sm){
			display: block;
		}
	}
	.cart_head{
		@include media-breakpoint-down(sm){
			display: none;
		}
	}
	.prd_list{
		@include media-breakpoint-down(sm){
			padding: 10px 0;
			position: relative;
			display: block;
			border: 1px solid #4C647B;
		}
		+ .prd_list{
			@include media-breakpoint-down(sm){
				border-top: none;
			}
		}
		td{
			@include media-breakpoint-down(sm){
				display: block;
				border: none;
			}
		}
		.count_prd{
			text-align: center;
		}
	}
	.all_price{
		@include media-breakpoint-down(sm){
			display: block;
		}
		td{
			@include media-breakpoint-down(sm){
				display: block;
				text-align: center;
				border: none;
			}
		}
	}
	.cart_del{
		@include media-breakpoint-down(sm){
			position: absolute;
			top: 0;
			right: 0;
		}
		input{
			@include media-breakpoint-down(sm){
				width: 25px;
				height: 25px;
			}
		}
	}
}
.fbinps,
.fxtxt{
	@include media-breakpoint-down(sm){
		width: 100% !important;
		padding: 10px;
	}
}
.fb_input{
	.error{
		.frm_err{
			@include media-breakpoint-down(sm){
				display: block;
				padding: 5px 0;
				margin: 0;
			}
		}
	}
}
.fbbs{
	@include media-breakpoint-down(sm){
		font-size: 17px;
	}
}
.anketa{
	position: relative;
	max-width: 765px;
	min-height: 300px;
	margin: 0 auto;
	border-radius: 5px;
	background: #fff;
	box-shadow: 3px 3px 20px rgba(0, 0, 0, 0.1), inset 0 0 2px 0 rgba(0, 0, 0, 0.15);
	@include media-breakpoint-down(sm){
		padding: 0 25px;
		min-height: 100px;
	}
	@include media-breakpoint-down(xs){
		padding: 0;
	}
	form{
		padding: 50px 50px 50px;
	}
	.modal__featured {
		position: relative;
		width: 100%;
		max-width: 260px;
		min-height: 310px;
		padding: 20px;
		overflow: hidden;
		border-top-left-radius: 5px;
		border-bottom-left-radius: 5px;
		@include media-breakpoint-down(sm){
			display: none;
		}
	}
	.modal__circle{
		position: absolute;
		top: 0;
		left: 0;
		height: 200%;
		width: 200%;
		background: #fff url(../images/feedback.jpg) 140px center no-repeat;
		background-size: auto 50%;
		border-radius: 50%;
		-webkit-transform: translateX(-50%) translateY(-25%);
		transform: translateX(-50%) translateY(-25%);
	}
	.modal__product {
		position: absolute;
		top: 0;
		left: 50%;
		max-width: 85%;
		-webkit-transform: translateX(calc(-50% - 10px));
		transform: translateX(calc(-50% - 10px));
	}
	.sent{
		text-align: center;
		padding: 10px 0;
		font-weight: bold;
		flex: 1 1 100%;
	}
	.fb_id{
		position: relative;
		display: block;
		text-transform: uppercase;
		font-weight: bold;
		letter-spacing: .5px;
		color: #939393;
		+ .fb_input{
			margin-bottom: 25px;
		}
	}
	.fb_input{
		text-align: left;
		.fbinps {
			width: 100% !important;
			min-height: 30px;
			padding-left: 5px;
			padding-right: 5px;
			letter-spacing: .5px;
			border: 0;
			border-bottom: 2px solid #7e929d;
			border-radius: 0;
			&:focus,
			&:hover {
				outline: none;
				border-color: #4C647B;
			}
		}
		.error{
			.frm_err{
				display: block;
				margin: 10px 0 0;
				font-size: 12px;
			}
		}
	}
	.fbbs{
		color: #ffffff;
		padding: 12px 25px;
		font-size: 12px;
		letter-spacing: 1px;
		text-transform: uppercase;
		border-radius: 2px;
		outline: 0;
		box-shadow: 3px 3px 20px rgba(0, 0, 0, 0.2);
		transition: all .2s;
		&:hover,
		&:active,
		&:focus {
			background: #7e929d;
			transform: scale(1.1);
		}
	}
	.phone{
		position: absolute;
		left: 310px;
		bottom: 15px;
		text-align: center;
		@include media-breakpoint-down(sm){
			width: 100%;
			left: 0;
			text-align: center;
		}
		@include media-breakpoint-down(xs){
			bottom: 5px;
		}
		b{
			padding: 0 10px 0 0;
			@include media-breakpoint-down(xs){
				padding: 0;
			}
		}
		a{
			text-decoration: none;
			color: #000;
			@include media-breakpoint-down(xs){
				display: block;
			}
		}
		br{
			display: none;
		}
	}
}
.anketa_title{
	font-size: 20px;
	text-align: center;
	padding: 0 0 15px;
}
.consultant-title{
	font-weight: bold;
	text-align: center;
	padding: 0 0 10px;
}
.calc-body{
	position: relative;
	padding: 0 20px;
	@include media-breakpoint-down(xs){
		padding: 0;
	}
	&:before{
		display: none;
		content: '';
		position: absolute;
		left: 30px;
		top: 90px;
		width: 200px;
		height: 200px;
		background: url(../images/calculator-2.svg) 0 0 no-repeat;
		@include media-breakpoint-up(xl){
			display: block;
		}
	}
	.calc-ttl{
		font-weight: bold;
		text-align: center;
		text-transform: uppercase;
	}
	.calc-wnd{
		display: flex;
		flex-wrap: wrap;
		padding: 20px 10px;
		border-radius: 10px 10px 0 0;
		box-shadow: 3px 3px 20px rgba(0, 0, 0, 0.1), inset 0 0 2px 0 rgba(255, 255, 255, 0.15);
		@include media-breakpoint-up(xl){
			padding: 20px 10px 20px 220px;
		}
	}
	.calc-p1,
	.calc-p2,
	.calc-p3,
	.calc-p4,
	.calc-p5,
	.calc-p6,
	.calc-p7,
	.calc-p8,
	.calc-p9{
		flex: 1 1 33.333%;
		width: auto;
		margin: 0;
		@include media-breakpoint-down(xs){
			flex: 1 1 50%;
		}
		b{
			text-transform: uppercase;
		}
	}
	.calc-p1,
	.calc-p2{
		flex: 1 1 50%;
		text-align: center;
		padding: 0 0 20px;
		position: relative;
		z-index: 1;
		@include media-breakpoint-down(sm){
			flex: auto;
			width: calc(50% - 20px);
		}
		@include media-breakpoint-down(xs){
			flex: 1 1 100%;
			width: 100%;
		}
	}
	.calc-p1{
		z-index: 2;
		@include media-breakpoint-down(sm){
			margin-right: 10px;
		}
		@include media-breakpoint-down(xs){
			margin-right: 0;
		}
	}
	.calc-p2{
		@include media-breakpoint-down(sm){
			margin-left: 10px;
		}
		@include media-breakpoint-down(xs){
			margin-left: 0;
		}
	}
	.calc-p9{
		font-weight: bold;
	}
	.calc-pr4{
		display: none;
		position: absolute;
		font-size: 36px;
		top: 113px;
		left: 52px;
		width: 156px;
		text-align: right;
		font-weight: bold;
		color: #fff;
		padding: 0 5px;
		@include media-breakpoint-up(xl){
			display: block;
		}
	}
	.calc-btns{
		position: relative;
		display: flex;
		justify-content: flex-end;
		align-items: center;
		border-radius: 0 0 10px 10px;
		z-index: 0;
		@include media-breakpoint-down(xs){
			flex-direction: column;
		}
		.refresh{
			position: relative;
			order: 1;
			@include media-breakpoint-down(xs){
				margin: 5px 0 0;
			}
			.fas{
				font-size: 22px;
				position: absolute;
				left: 10px;
				top: 10px;
				color: #fff;
				z-index: 1;
			}
		}
		input{
			position: relative;
			color: #fff;
			text-align: center;
			text-transform: uppercase;
			margin: 0;
			border-radius: 5px;
			background: #4c89ab;
			text-indent: unset;
			&:hover{
				background: #7e929d;
			}
		}
		.calc-pr1{
			order: 0;
			padding: 0 10px 0 0;
			@include media-breakpoint-down(xs){
				font-size: 14px;
			}
		}
	}
	.calc-send-wnd{
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		border: none;
		text-align: center;
		&.csw-hidden{
			display: none !important;
		}
		.calc-send-ttl{
			font-weight: bold;
			text-align: center;
			text-transform: uppercase;
			flex: 1 1 100%;
		}
		.calc-s-p1,
		.calc-s-p2,
		.calc-s-p3{
			width: 32.333%;
			margin: 0 0 10px;
			@include media-breakpoint-down(xs){
				width: 100%;
			}
			input{
				width: 100%;
				height: 35px;
				border-radius: 5px;
			}
		}
		.calc-s-p4{
			flex: 1 1 100%;
			textarea{
				width: 100%;
				height: 100px;
				border-radius: 5px;
			}
		}
		.calc-s-p5{
			display: flex;
			justify-content: flex-end;
			flex: 1 1 100%;
			text-align: right;
			input{
				position: relative;
				color: #fff;
				text-align: center;
				text-transform: uppercase;
				margin: 0;
				border-radius: 5px;
				background: #4c89ab;
				text-indent: unset;
				&:hover{
					background: #7e929d;
				}
			}
			.send{
				position: relative;
				.fab{
					font-size: 22px;
					position: absolute;
					left: 10px;
					top: 10px;
					color: #fff;
					z-index: 1;
				}
			}
		}
	}
	/** Custom Select **/
	.custom-select-wrapper {
		position: relative;
		display: inline-block;
		user-select: none;
		@include media-breakpoint-down(xs){
			display: block;
		}
	}
	.custom-select-wrapper select {
		display: none;
	}
	.custom-select {
		position: relative;
		display: inline-block;
		@include media-breakpoint-down(xs){
			display: block;
		}

	}
	.custom-select-trigger {
		position: relative;
		display: block;
		min-width: 245px;
		padding: 0 42px 0 10px;
		font-size: 16px;
		color: #fff;
		line-height: 40px;
		background: #4c89ab;
		border-radius: 4px;
		cursor: pointer;
		@include media-breakpoint-down(sm){
			font-size: 14px;
			min-width: 210px;
			padding: 0 30px 0 10px;
		}
		@include media-breakpoint-down(xs){
			min-width: 100%;
			width: 100%;
		}
	}
	.custom-select-trigger:after {
		position: absolute;
		display: block;
		content: '';
		width: 10px; 
		height: 10px;
		top: 50%;
		right: 15px;
		margin-top: -3px;
		border-bottom: 1px solid #fff;
		border-right: 1px solid #fff;
		transform: rotate(45deg) translateY(-50%);
		transition: all .4s ease-in-out;
		transform-origin: 50% 0;
	}
	.custom-select.opened .custom-select-trigger:after {
		margin-top: 3px;
		transform: rotate(-135deg) translateY(-50%);
	}
	.custom-options {
		position: absolute;
		display: block;
		top: 100%; left: 0; right: 0;
		min-width: 100%;
		margin: 5px 0;
		border: 1px solid #b5b5b5;
		border-radius: 4px;
		box-sizing: border-box;
		box-shadow: 0 2px 1px rgba(0,0,0,.07);
		background: #fff;
		transition: all .4s ease-in-out;

		opacity: 0;
		visibility: hidden;
		pointer-events: none;
		transform: translateY(-15px);
	}
	.custom-select.opened .custom-options {
		opacity: 1;
		visibility: visible;
		pointer-events: all;
		transform: translateY(0);
	}
	.custom-options:before {
		position: absolute;
		display: block;
		content: '';
		bottom: 100%; right: 25px;
		width: 7px; height: 7px;
		margin-bottom: -4px;
		border-top: 1px solid #b5b5b5;
		border-left: 1px solid #b5b5b5;
		background: #fff;
		transform: rotate(45deg);
		transition: all .4s ease-in-out;
	}
	.option-hover:before {
		background: #f9f9f9;
	}
	.custom-option {
		position: relative;
		display: block;
		padding: 0 10px;
		border-bottom: 1px solid #b5b5b5;
		font-size: 14px;
		color: #000;
		line-height: 27px;
		cursor: pointer;
		transition: all .4s ease-in-out;
		&:hover{
		}
	}
	.custom-option:first-of-type {
		border-radius: 4px 4px 0 0;
	}
	.custom-option:last-of-type {
		border-bottom: 0;
		border-radius: 0 0 4px 4px;
	}
	.custom-option:hover,
	.custom-option.selection {
		background: #4c89ab;
		color: #fff;
	}

	label {
		display: inline-flex;
		align-items: baseline;
		text-transform: capitalize;
		padding: 0 0 10px 0;
		&.active{
			color: #000;
		}
		input[type=checkbox],
		input[type=radio] {
			position: relative;
			appearance: none;
			font-size: inherit;
			width: 1em;
			margin: 0 10px 0 0;
			color: inherit;
			outline: none;
			font-family: 'Font Awesome 5 Free';
			transition: 300ms ease-out;

			&::after {
      content: '\f111'; // circle
      display: inline-block;
      text-align: center;
      width: 1em;
    }

    &:checked::after {
    	font-weight: 900;
    }
    &:active {
    	transform: scale(.6);
    }

    + span {
    	margin-left: .35em;
    }
  }
  
  input[type=checkbox]:checked::after {
    content: '\f058'; // check-circle
    color: #4c89ab;
  }
  input[type=checkbox]:checked + span.param {
  	color: #000;
  	font-weight: bold;
  }

  input[type=radio]:checked::after {
    content: '\f192'; // dot-circle
  }
}

.fancy.square label {
	input[type=checkbox]:after {
    content: '\f0c8'; // square
    color: #4c89ab;
  }
  input[type=checkbox]:checked::after {
    content: '\f14a'; // check-square
  }
}

}